import Vue from 'vue';
import MessageBox from './MessageBox.vue';

function withShowMessagBox(isConfirm) {
  return (message, cancel, confirm, callback) => {
    const Mb = Vue.extend(MessageBox);
    const el = document.createElement('div');
    const propsData = {
      message,
      isConfirm,
      cancel,
      confirm,
    };
    return new Promise((resolve) => {
      const component = new Mb({
        el,
        propsData,
        methods: {
          onClick(str) {
            resolve(str);
            document.body.removeChild(component.$el);
            if (callback) callback();
          },
        },
      });
      document.body.appendChild(component.$el);
    });
  };
}

export const showConfirm = withShowMessagBox(true);

export const showAlert = withShowMessagBox(false);
